import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import { useStep } from '@/hooks/wizard/useStep';
import useValidation from '@/hooks/wizard/useValidation';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import Input from '@/elements/Input';

export default function AddressTypeStep() {
    const dispatch = useDispatch();
    const { request, options } = useSelector((state) => state.requestWizard);
    const { completeStep } = useValidation();
    const { next } = useStep();

    return (
        <Step size="sm">
            <h4 className="step-title">Where is your event?</h4>
            <p className="step-info text-wrap">
                What type of accomodation are you planning to host your event
                in.
            </p>
            <Input
                cols={1}
                inputType="checkbox"
                value={request.addressType || ''}
                handleChange={(v) => {
                    dispatch(updateRequest('addressType', v));
                    completeStep();
                    next();
                }}
                className="input__checkbox"
                options={options.addressType}
            />
        </Step>
    );
}
