import React, { useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateRequest,
    getChefsCount,
} from '@/actions/wizard/RequestWizardActions';
import { evaluateCondition } from '@/utils/helpers';
import useValidation from '@/hooks/wizard/useValidation';
import PriceConverter from '@/components/Pricing/PriceConverter';
import BudgetCheckBoxes from './BudgetCheckBoxes';
import Input from '@/elements/Input';
import { debounce } from 'lodash';
import { NYE, christmas } from '@/constants/dates';

export default function BudgetInput({ budgetTypes, selectedOption }) {
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const { meta, request, options } = useSelector(
        (state) => state.requestWizard
    );
    const { completeStep, stepError } = useValidation();

    const delayChefCount = useCallback(
        debounce(() => dispatch(getChefsCount()), 500),
        []
    );
    const interruptDelay = () => delayChefCount.cancel();

    // useEffect(() => {
    //     if (request.budget > 0 && options.chefsCount > 0) {
    //         dispatch(updateMeta('activePrompt', 'chefs-count'));
    //     }
    // }, [meta.step]);

    useEffect(() => {
        if (!inputRef.current) return;
        scrollToInput();
    }, [inputRef.current]);

    const scrollToInput = () => {
        if (!inputRef.current) return;
        $(`#${meta.modalId}`).animate(
            {
                scrollTop: $(inputRef.current).offset().top,
            },
            500
        );
    };

    const isNYE = evaluateCondition('contains', request.mealDate, NYE);
    const isChristmas = evaluateCondition(
        'contains',
        request.mealDate,
        christmas
    );

    const validateBudget = (
        total = request.budget,
        perPerson = request.budget_pp,
        includingGrocery = request.includingGrocery
    ) => {
        let minBudget = 100,
            minBudgetPerPerson = 20;

        if (includingGrocery === false) {
            minBudgetPerPerson = 0;
        }

        if (isChristmas) {
            minBudget = 1500;
        }

        if (isNYE) {
            minBudget = 500;
        }

        if (perPerson >= minBudgetPerPerson && total >= minBudget) {
            completeStep();
            // delayChefCount();
            return;
        }

        interruptDelay();

        const errorMsg =
            perPerson < minBudgetPerPerson
                ? `Minimum budget per person is £${minBudgetPerPerson}.`
                : total < minBudget
                ? `Minimum budget is ${minBudget}.`
                : 'Please enter your budget.';

        if (isNYE) {
            stepError(
                `31st Dec (New Years Eve) is a peak day. The minimum spend is £${minBudget}.`
            );
        } else if (isChristmas) {
            stepError(
                `25th Dec (Christmas Day) is a peak day. The minimum spend is £${minBudget}.`
            );
        } else {
            stepError();
        }
    };

    const handleChange = (option, total, perPerson) => {
        dispatch(
            updateRequest({
                budgetEntry: option,
                budget: total,
                budget_pp: perPerson,
            })
        );

        // Stop here if just switching between options
        if (option !== request.budgetEntry) return;

        validateBudget(total, perPerson);
    };

    const renderConvertedPrice = () => {
        if (!(request.budget_pp > 0 || request.budget > 0)) return;

        let copy = '';

        if (request.budgetEntry === 'perPerson') {
            copy = `That's £${parseFloat(request.budget).toFixed(2)} in total`;
        } else {
            copy = `That's £${parseFloat(request.budget_pp).toFixed(
                2
            )} per person`;
        }

        return <p className="mb-0 mt-1">{copy}</p>;
    };

    const renderSuggestedBudget = () => {
        if (!options.suggestedBudget || !selectedOption) return;

        const min = parseFloat(options.suggestedBudget[0]).toLocaleString();
        const max =
            options.suggestedBudget[1] &&
            parseFloat(options.suggestedBudget[1]).toLocaleString();

        const estimate = max ? `£${min} - £${max}` : `around £${min}`;

        return `Based on ${request.guests} guests, the range for a ${selectedOption.label} chef is ${estimate}`;
    };

    if (budgetTypes?.options?.length > 0 && !request.budgetType) return;

    return (
        <div ref={inputRef} className="mt-4">
            <Input
                id="wizard-budget-input"
                inputType="custom"
                label="Total budget"
                info={renderSuggestedBudget()}>
                <BudgetCheckBoxes validateBudget={validateBudget} />
                <PriceConverter
                    id="wizard-budget-input"
                    guests={request.guests}
                    total={request.budget}
                    perPerson={request.budget_pp}
                    selectedOption={request.budgetEntry || 'total'}
                    handleChange={handleChange}
                    hideSwitch={true}
                    // autoFocus={true}
                />
                {renderConvertedPrice()}
            </Input>
        </div>
    );
}
