import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import { useStep } from '@/hooks/wizard/useStep';
import useValidation from '@/hooks/wizard/useValidation';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import Input from '@/elements/Input';
import Feature from '@/utils/Features/Feature';
import { usePrevious } from '@/utils/hooks';

export default function OccasionStep() {
    const dispatch = useDispatch();
    const { request, options, meta } = useSelector(
        (state) => state.requestWizard
    );
    const { completeStep } = useValidation();
    const { next } = useStep();
    const prevRequest = usePrevious(request);

    const mapOption = (option) => {
        return {
            label: (
                <>
                    <option.icon
                        size={meta.isMobile ? 20 : 24}
                        className="mr-2"
                    />
                    {option.label}
                </>
            ),
            value: option.label,
        };
    };

    // attributes for rented venue flow conditions
    let isRentedVenueFlow =
        meta?.features?.feature_wizard_rented_venue_flow &&
        meta?.features?.feature_wizard_str;

    let isHolidayHome =
        request.addressType == 'Holiday home' && isRentedVenueFlow;

    let occationTypes = options.type;

    if (isHolidayHome && isRentedVenueFlow) {
        occationTypes = options.holidayHomeEventType;
    }

    const occasionOptions = occationTypes.map((option) => mapOption(option));

    const handleSelect = (v) => {
        let isRestaurantFood = v == 'Restaurant like meal';

        if (
            v === 'Meal prep' ||
            request.type === 'Meal prep' ||
            (isRentedVenueFlow && !isRestaurantFood)
        ) {
            dispatch(updateRequest('servingType', ''));
            completeStep(false, 'food-type');
        }
        dispatch(updateRequest('type', v));
        completeStep();

        if (!isRentedVenueFlow) {
            next();
        }
    };

    /**
     * If the rented venue flow is enabled, we need to wait for the state to update
     * before moving to the next step, so do it based on the previous and current state
     */
    useEffect(() => {
        if (!isRentedVenueFlow) return;

        if (
            (prevRequest?.type &&
                prevRequest?.type !== request?.type &&
                request?.type) ||
            (prevRequest?.type == '' && prevRequest?.type !== request?.type)
        ) {
            next();
        }
    }, [request.type]);

    return (
        <Step>
            <h4 className="step-title">What type of event are you planning?</h4>
            <p className="step-info">
                Give us a few details to see chefs, menus and prices. It will
                take &lt;2min.
            </p>
            <Input
                cols={isHolidayHome ? 1 : 3}
                inputType="checkbox"
                value={request.type}
                handleChange={(v) => handleSelect(v)}
                className="input__checkbox"
                options={occasionOptions}
            />
        </Step>
    );
}
