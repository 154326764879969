import React, { useState, useEffect, forwardRef } from 'react';
import useAutoFocus from '@/hooks/inputs/useAutoFocus';
import WhenEvent from '@/views/FoodExplorer/UserOptionsContainer/WhenEvent/WhenEvent';
import NumOfGuests from '@/views/FoodExplorer/UserOptionsContainer/NumOfGuests/NumOfGuests';
import Autocomplete from 'react-autocomplete';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import Slider from 'rc-slider';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import uniqid from 'uniqid';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import CheckBox from './inputs/CheckBox';
import DateInput from './inputs/DateInput';
import What3words from './inputs/What3words';
import StarRating from './inputs/StarRating';
import { Info } from '@phosphor-icons/react';

/**
 *
 * @param string inputType (text|money|time|textarea|date|number|select)
 * @param string label
 * @param string value
 * @param int decimals
 * @param string error
 * @param function handleChange
 * @param array options
 * @param bool inline
 * @param string inputClass
 * @param string labelClass
 * @param string errorClass
 * @param ReactNode icon
 * @param string sliderClass
 * @param string infoIcon
 *
 * @usage
 * <Input
 *    inputType="text"
 *    label="Name"
 *    value={name}
 *    error={errors.name}
 *    handleChange={value => setName(value)}
 * />
 *
 * @returns
 */
// const Input = forwardRef(({
//     handleChange = () => {},
//     isMulti = false,
//     inputType = 'text',
//     children,
//     ...props
// }), ref) => {

const Input = forwardRef(
    (
        {
            handleChange = () => {},
            isMulti = false,
            inputType = 'text',
            children,
            ...props
        },
        ref
    ) => {
        const uid = uniqid(),
            {
                id = 'input-' + uid,
                label = false,
                info = false,
                placeholder = label && label != 'false'
                    ? label
                    : 'Start typing...',
                decimals = 0,
                options = [],
                inline = false,
                error = false,
                cols = 1,
                limit = false,
                inputClass = '',
                labelClass = '',
                errorClass = '',
                sliderClass = '',
                infoIcon = '',
                icon = false,
            } = props;
        let { value } = props;

        const inputClasses = `form-control ${
            error ? 'is-invalid' : ''
        } ${inputClass}`;

        const [collapseOptions, setCollapseOptions] = useState(true);
        const [diff, setDiff] = useState(0);

        const optionsMap =
            collapseOptions && limit && options?.length > limit
                ? options.slice(0, limit)
                : options;

        useEffect(() => {
            if (options && limit && options.length > 0) {
                setDiff(options.length - limit);
            }
        }, [options, limit]);

        const handleInputChange = (ev) => {
            if (
                inputType === 'event-date' ||
                inputType === 'guest-counter' ||
                (inputType === 'checkbox' && options && options.length > 0) ||
                inputType === 'select' ||
                inputType === 'select-creatable' ||
                inputType === 'autocomplete' ||
                inputType === 'slider' ||
                inputType === 'date' ||
                inputType === 'star-rating'
            )
                return handleChange(ev);

            // input type checkbox and no options
            if (
                inputType === 'checkbox' &&
                (!options || options.length === 0)
            ) {
                return handleChange(ev.target.checked);
            }

            return handleChange(ev.target.value);
        };

        // Decimal formatting
        if (value && (inputType === 'money' || inputType === 'number')) {
            value = parseFloat(value).toFixed(parseInt(decimals));
        }

        // Shared props with all input types
        const baseProps = {
            ...props,
            id: id,
            className: inputClasses,
            placeholder: placeholder,
            value: value,
            onChange: (ev) => handleInputChange(ev),
        };
        delete baseProps.labelClass;
        delete baseProps.inputClass;
        delete baseProps.info;

        if (props.autoFocus) {
            const inputRef = useAutoFocus();
            baseProps.ref = inputRef;
        }

        const renderInput = () => {
            switch (inputType) {
                case 'text':
                    return <input ref={ref} {...baseProps} />;
                case 'money':
                    return (
                        <div data-prefix="£">
                            <input
                                {...baseProps}
                                type="number"
                                min={props.min ?? '20'}
                                max={props.max ?? '10000'}
                            />
                        </div>
                    );
                case 'time':
                    return (
                        <input
                            {...baseProps}
                            type="time"
                            min={props.min ?? '09:00'}
                            max={props.max ?? '22:45'}
                        />
                    );
                case 'textarea':
                    return (
                        <textarea {...baseProps} rows={props.rows ?? '7'} />
                    );
                case 'date':
                    return (
                        <div className="flex items-center gap-2 relative">
                            <DayPickerInput
                                {...baseProps}
                                format="DD/MM/yyyy"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                onDayChange={baseProps.onChange}
                                dayPickerProps={{
                                    localeUtils: MomentLocaleUtils,
                                    firstDayOfWeek: 1,
                                }}
                            />
                            {value && (
                                <button
                                    className="btn btn-sm p-2 absolute t- top-[50%] transform -translate-y-1/2 right-1"
                                    onClick={() => handleChange('')}>
                                    <i className="yh-new-icon-Cross---Circle text-2xl"></i>
                                </button>
                            )}
                        </div>
                    );
                case 'date-range':
                    return <DateInput {...baseProps} />;

                case 'event-date':
                    return (
                        <div className="d-flex flex-col">
                            <WhenEvent
                                {...baseProps}
                                mealDate={value}
                                updateMealDate={(ev) => handleInputChange(ev)}
                            />
                        </div>
                    );
                case 'guest-counter':
                    return (
                        <NumOfGuests
                            {...baseProps}
                            guests={value}
                            updateGuests={(ev) => handleInputChange(ev)}
                            min={props.min ?? 0}
                            max={props.max ?? 100}
                        />
                    );
                case 'checkbox':
                    return (
                        <>
                            {options && options.length > 0 ? (
                                <div
                                    className={`input-grid yh-pill-container !items-stretch ${props.className ?? ''}`}
                                    data-cols={cols}
                                    style={
                                        cols
                                            ? {
                                                    display: 'grid',
                                                    gridTemplateColumns:
                                                        'repeat(' +
                                                        cols +
                                                        ', 1fr)',
                                                    gap: '8px',
                                                }
                                            : {}
                                    }>
                                    {optionsMap.map((option, idx) => (
                                        <CheckBox
                                            {...baseProps}
                                            // id={option.id ?? undefined}
                                            id={`checkbox-${uniqid()}`}
                                            key={`checkbox-${idx}`}
                                            isMulti={isMulti}
                                            select={(ev) => {
                                                console.log(
                                                    'handlechange input',
                                                    ev
                                                );
                                                handleInputChange(ev);
                                            }}
                                            isSelected={value?.includes(
                                                option.value
                                            )}
                                            onChange={false}
                                            disabled={props.disabled}
                                            {...option}
                                            // Apply responsive font size
                                            className="text-xs md:text-sm lg:text-base"
                                        />
                                    ))}
                                </div>
                            ) : (
                                <CheckBox
                                    {...baseProps}
                                    isMulti={true}
                                    onChange={handleInputChange}
                                    select={false}
                                />
                            )}
                        </>
                    );
                case 'select':
                    baseProps.value = undefined;

                    return (
                        <Select
                            ref={ref}
                            value={value}
                            {...baseProps}
                            options={options}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={isMulti ?? false}
                            classNamePrefix="multi-select"
                            className={error ? 'is-invalid' : ''}
                            placeholder={props.placeholder ?? `Type or select ${label?.toLowerCase()}`}
                        />
                    );
                    break;
                case 'select-creatable':
                    baseProps.value = undefined;
                    return (
                        <CreatableSelect
                            {...baseProps}
                            className={error ? 'is-invalid' : ''}
                            classNamePrefix="multi-select"
                            defaultValue={
                                value
                                    ? Array.isArray(value)
                                        ? value
                                        : _.find(
                                              options,
                                              (option) => option.value == value
                                          ) ?? { label: value, value: value }
                                    : null
                            }
                            isClearable={false}
                            isSearchable={props.isSearchable ?? true}
                            placeholder={
                                props.placeholder ??
                                `Type or select ${label ? label.toLowerCase() : ''}`
                            }
                            options={options}
                            isMulti={isMulti ?? false}
                            {...props.creatableProps ?? {}}
                            // menuIsOpen={true}
                        />
                    );
                    break;
                case 'autocomplete':
                    if (is_node) {
                        delete baseProps.dropdownClass;
                        return <input type="text" {...baseProps} />;
                    }
                    return (
                        <div className="autocomplete-wrapper">
                            <Autocomplete
                                {...baseProps}
                                items={options}
                                getItemValue={(item) => item}
                                renderItem={(item, isHighlighted) => (
                                    <div
                                        className={`dropdown-item autocomplete-item ${
                                            isHighlighted
                                                ? 'is_highlighted'
                                                : ''
                                        }`}>
                                        {item}
                                    </div>
                                )}
                                onChange={(e, v) => baseProps.onChange(v)}
                                inputProps={{
                                    id: baseProps.id,
                                    className: baseProps.className,
                                    placeholder: baseProps.placeholder,
                                }}
                                renderMenu={(items, value, style) => {
                                    if (!items || items.length === 0)
                                        return <></>;
                                    return (
                                        <div
                                            className={`dropdown-menu autocomplete-menu show position-fixed ${
                                                props.dropdownClass ?? ''
                                            }`}
                                            style={{ ...style }}
                                            children={items}
                                        />
                                    );
                                }}
                                // open={true}
                            />
                        </div>
                    );
                    break;
                case 'slider':
                    return (
                        <div data-prefix="£">
                            <Slider
                                {...baseProps}
                                allowCross={false}
                                defaultValue={value}
                                value={value}
                                className={`bar-chart_slider ${sliderClass}`}
                                tipProps={{ visible: true }}
                            />
                        </div>
                    );
                case 'what3words':
                    return (
                        <What3words
                            {...baseProps}
                            handleChange={handleChange}
                        />
                    );
                    break;
                case 'star-rating':
                    return (
                        <StarRating
                            onChange={handleChange}
                            rating={value}
                            {...baseProps}
                        />
                    );
                    break;
                case 'custom':
                    return children;
                    break;
                default:
                    return <input type={inputType} {...baseProps} />;
                    break;
            }
        };

        return (
            <div className={`form-group ${props.className ?? ''}`}>
                <div className={inline ? `-inline` : ''}>
                    <div>
                        {/* Show input label */}
                        {label &&
                            !(
                                inputType === 'checkbox' &&
                                (!options || options.length === 0)
                            ) && (
                                <div>
                                    <label
                                        htmlFor={id}
                                        className={labelClass}
                                        dangerouslySetInnerHTML={{
                                            __html: `${label}${props.required ? '<small class="font-sm ml-2">(Required)</small>' : ''}`,
                                        }}
                                    />
                                    {infoIcon && (
                                        <Info
                                            size={18}
                                            weight="regular"
                                            title={infoIcon}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            className="ml-2 vertical-align-middle"
                                        />
                                    )}
                                </div>
                            )}
                        {info && (
                            <p className="form-text fs-14 font-dm-sans -text-grey-700 mb-3">
                                {info}
                            </p>
                        )}
                    </div>
                    <div className={`position-relative ${icon && '-has-icon'}`}>
                        {/* Show icon */}
                        {icon && (
                            <label htmlFor={id} className="input-icon">
                                {icon}
                            </label>
                        )}

                        {/* Show input */}
                        {renderInput()}

                        {children && inputType != 'custom' && <>{children}</>}

                        {/* Show more button if options are limited */}
                        {collapseOptions && diff > 0 && (
                            <a
                                href="#"
                                className="font-dm-sans fs-14 fw-bold d-inline-block w-100 mt-3"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setCollapseOptions(false);
                                }}>
                                Show all ({diff})
                            </a>
                        )}
                    </div>
                </div>

                {/* Show error message if input is invalid */}
                {error && typeof error === 'string' && (
                    <p className={`invalid-feedback d-block ${errorClass}`}>
                        {error}
                    </p>
                )}
            </div>
        );
    }
);

export default Input;
