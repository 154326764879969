import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateRequest,
    updateOptions,
} from '@/actions/wizard/RequestWizardActions';
import Input from '@/elements/Input';

export default function BudgetOptions({ budgetTypes, selectedOption }) {
    const dispatch = useDispatch();
    const { meta, request } = useSelector((state) => state.requestWizard);

    // Update suggested budget
    useEffect(() => {
        if (!selectedOption || !selectedOption.priceRange) {
            dispatch(updateOptions('suggestedBudget', false));
            return;
        }

        let min = selectedOption.priceRange[0] * request.guests,
            max = (selectedOption.priceRange[1] ?? 3000) * request.guests;

        const lowerBound = selectedOption.lowerBound || selectedOption.minSpend;
        if (min < lowerBound) min = lowerBound;
        if (max <= min) max = false;

        dispatch(updateOptions('suggestedBudget', [min, max]));
    }, [meta.step, selectedOption]);

    const budgetOptions = budgetTypes?.options;
    if (!budgetOptions) return;

    const minSpendCopy =
        request.type == 'Full-time chef' ||
        request.type == 'Multi-day chef hire'
            ? 'Min day rate'
            : 'Min spend';

    const mappedOptions = budgetOptions.map((option, idx) => {
        return {
            label: (
                <>
                    <strong>{option.label}</strong>
                    {(option.priceRange || option.minSpend) && (
                        <p className="fs-14 fw-normal m-0">
                            {option.priceRange?.length === 2 &&
                                `£${option.priceRange?.[0]} - £${option.priceRange?.[1]}pp`}
                            {option.priceRange?.length === 1 &&
                                `£${option.priceRange?.[0]}pp+`}
                            {option.priceRange && option.minSpend && ' | '}
                            {option.minSpend &&
                                `${minSpendCopy} £${option.minSpend.toLocaleString()}`}
                        </p>
                    )}
                    {option.description && (
                        <p className="fs-14 fw-normal m-0">
                            {option.description}
                        </p>
                    )}
                    {idx == 1 && (
                        <span className="badge mt-1 badge-violet-200">
                            Most popular
                        </span>
                    )}
                </>
            ),
            value: option.value,
        };
    });

    return (
        <Input
            cols={
                meta.isMobile
                    ? 1
                    : budgetOptions.length < 3
                    ? budgetOptions.length
                    : 3
            }
            inputType="checkbox"
            value={[request.budgetType]}
            handleChange={(v) => dispatch(updateRequest('budgetType', v))}
            className={`input__checkbox -radio [&_.form-styling:nth-child(2)_label]:border-2`}
            optionClass="[&>span]:w-full"
            options={mappedOptions}
        />
    );
}
