import { createAction, createReducer } from '@reduxjs/toolkit';
import { initial } from 'lodash';
import { getLocalStorage } from '../utils/helpers';

import {
    FETCH_MESSAGES,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_ERROR,
    UPDATE_MESSAGE,
    UPDATE_FILES,
    UPDATE_FILES_SUCCESS,
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_ERROR,
    FETCH_RECIPIENTS,
    FETCH_RECIPIENTS_SUCCESS,
    FETCH_RECIPIENTS_ERROR,
    FILTER_MESSAGES,
    FILTER_MESSAGES_CLEAR,
    FETCH_RECIPIENTS_PAGINATE_SUCCESS,
    SET_MESSAGE_CONFIG,
} from '../constants/ActionTypes';

const emptyFilters = {
    show_archived: false,
    show_starred: false,
    direct_quotes: false,
    unread_messages: false,
    customer_messaged_last: false,
    bookings: false,
    quotes: false,
    meal_date: '',
    name: '',
};

const initialState = {
    loading: false,
    hasLoaded: false,
    config: {
        hasInit: false,
        resourceId: false,
        chefId: false,
        type: false,
        isCustomer: false,
        recipientId: false,
        future: true,
        status: false,
        chef_slug: '',
        last_seen: false,
        conversationId: false,
        showExistingConversations: true,
    },
    messageList: {
        errors: [],
        messages: [],
        quote: false,
        order: false,
        conversation: false,
        pagination: {
            links: false,
            meta: false,
        },
    },
    newMessage: {
        body: '',
        uploads: {
            files: [],
            loading: false,
            hasLoaded: true,
        },
        sending: false,
        errors: false,
    },
    recipients: {
        loading: false,
        hasLoaded: false,
        errors: [],
        upcomingOrders: [],
        users: [],
        upcoming: [],
        past: [],
        filters: emptyFilters,
        selectedFilters: emptyFilters,
        newConversations: false,
        pagination: {
            links: false,
            meta: false,
        },
    },
};

export const messages = createReducer(initialState, (builder) => {
    builder
        .addCase('INIT_MESSAGES', (state, action) => {
            state.config.chefId = action.payload.chefId;
            state.config.resourceId = action.payload.resourceId;
            state.config.type = action.payload.type;
            state.config.recipientId = action.payload.recipientId;
            state.config.conversationId = action.payload.conversationId;
            state.config.hasInit = true;
        })
        .addCase('FETCH_MESSAGES', (state) => {
            state.loading = true;
            state.hasLoaded = false;
            state.messageList = initialState.messageList;
        })
        .addCase('FETCH_MESSAGES_SUCCESS', (state, action) => {
            let messages = action.payload?.messages;
            let isPaginated = action.payload?.isPaginated;
            state.loading = false;
            state.hasLoaded = true;
            state.config.isCustomer = action.payload?.isCustomer;

            if (isPaginated) {
                state.messageList.messages = [
                    ...messages.data.reverse(),
                    ...state.messageList.messages,
                ];
            } else {
                state.messageList.messages = messages.data.reverse();
                state.messageList.order = action.payload?.order;
                state.messageList.quote = action.payload?.quote;
                state.messageList.conversation = action.payload?.conversation;

                if (messages.data.length > 0) {
                    let lastMessage = messages.data.slice(-1).pop();
                    state.config.resourceId = lastMessage?.order_id
                        ? lastMessage?.order_id
                        : lastMessage?.quote_id;
                    state.config.type = lastMessage?.order_id
                        ? 'order'
                        : 'quote';
                    state.config.chefId = lastMessage?.chef_id;
                    state.config.chef_slug = lastMessage?.chef_slug;
                    state.config.last_seen = state.config.isCustomer
                        ? lastMessage?.chef_last_seen
                        : lastMessage?.customer_last_seen;
                    state.config.conversationId =
                        action.payload?.conversation?.id;

                    const today = new Date().setHours(0, 0, 0, 0),
                        orderDate = new Date(
                            action.payload?.order?.meal_date
                        ).setHours(0, 0, 0, 0);
                    state.config.future = orderDate < today ? false : true;
                    state.config.status = action.payload?.order?.order_status;
                }
            }

            state.messageList.errors = action.payload?.errors;

            state.messageList.pagination = {
                links: messages?.links,
                meta: messages?.meta,
            };
        })
        .addCase('FETCH_MESSAGES_ERROR', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.messageList.errors = action.payload?.errors;
        })
        .addCase('UPDATE_MESSAGE', (state, action) => {
            state.newMessage.body = action.payload;
        })
        .addCase('UPDATE_FILES', (state, action) => {
            state.newMessage.uploads.files =
                initialState.newMessage.uploads.files;
            state.newMessage.uploads.loading = true;
            state.newMessage.uploads.hasLoaded = false;
        })
        .addCase('UPDATE_FILES_SUCCESS', (state, action) => {
            state.newMessage.uploads.files = action.payload;
            state.newMessage.uploads.loading = false;
            state.newMessage.uploads.hasLoaded = true;
        })
        .addCase('SEND_MESSAGE', (state) => {
            state.newMessage.sending = true;
        })
        .addCase('SEND_MESSAGE_SUCCESS', (state, action) => {
            let messages = action.payload?.messages;

            state.messageList.messages = messages?.data?.reverse();

            // state.messageList.pagination = {
            //     links: messages?.links,
            //     meta: messages?.meta,
            // };

            state.newMessage.sending = false;
            state.newMessage.errors = false;
            state.newMessage.body = '';
            state.newMessage.uploads.files = [];
        })
        .addCase('SEND_MESSAGE_ERROR', (state, action) => {
            state.newMessage.sending = false;
            state.newMessage.errors = action.payload?.data.errors;
        })
        .addCase('FETCH_RECIPIENTS', (state, action) => {
            state.recipients.loading = true;

            state.recipients.hasLoaded = action.payload.hasLoaded;
        })
        .addCase('FETCH_RECIPIENTS_SUCCESS', (state, action) => {
            state.recipients.loading = false;
            state.recipients.hasLoaded = true;
            state.recipients.errors = initialState.recipients.errors;
            state.config.isCustomer = action.payload?.isCustomer;
            state.recipients.upcomingOrders = action.payload?.upcomingOrders;
            state.recipients.newConversations =
                action.payload?.newConversations;

            // localStorage.setItem('yh-message-upcoming-orders', JSON.stringify(upcomingOrders));

            // let selectedUser = state.recipients.users.find((user) => return user.id === state.config.recipientId; })
            // console.log({ selectedUser})

            const users = action.payload?.users?.data;
            state.recipients.users = users;
            // localStorage.setItem('yh-message-users', JSON.stringify(users));

            // const upcomingUsers = action.payload?.users?.data.filter((user) => {
            //     return user.upcoming;
            // });
            // state.recipients.upcoming = upcomingUsers;
            // localStorage.setItem('yh-message-upcoming-users', JSON.stringify(upcomingUsers));

            // const pastUsers = action.payload?.users?.data.filter((user) => {
            //     return !user.upcoming;
            // });
            // state.recipients.past = pastUsers;
            // localStorage.setItem('yh-message-past-users', JSON.stringify(pastUsers));
            state.recipients.pagination = {
                ...state.pagination,
                links: action.payload.users?.links,
                meta: action.payload.users?.meta,
            };
        })
        .addCase('FETCH_RECIPIENTS_PAGINATE_SUCCESS', (state, action) => {
            state.recipients.loading = false;
            state.recipients.hasLoaded = true;
            state.recipients.errors = initialState.recipients.errors;
            const users = action.payload?.users?.data;

            state.recipients.users = [...state.recipients.users, ...users];

            state.recipients.pagination = {
                ...state.pagination,
                links: action.payload.users?.links,
                meta: action.payload.users?.meta,
            };
        })
        .addCase('FETCH_RECIPIENTS_ERROR', (state, action) => {
            state.recipients.loading = false;
            state.recipients.hasLoaded = true;
            state.recipients.errors = action.payload?.errors;
        })
        .addCase('FILTER_MESSAGES', (state, action) => {
            state.recipients.selectedFilters[action.payload.filter] =
                action.payload.value;
        })
        .addCase('FILTER_MESSAGES_CLEAR', (state, action) => {
            state.recipients.selectedFilters = emptyFilters;
        })
        .addCase('SET_MESSAGE_CONFIG', (state, action) => {
            // toggle showExistingConversations to the opposite of what it is
            state.config[action.payload.key] = action.payload.value;
        });
});
