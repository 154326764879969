import { currencySigns } from '@/constants/disintermediation';

/**
 * Maps a phrase to a regex pattern that matches the phrase with any number of spaces
 * between each character to catch cases such as "i n s t a g r a m"
 *
 * @param {string} phrase - word or phrase to map to a regex pattern
 * @returns {string} regex pattern
 */
export const spaceBetweenChars = (phrase) =>
    phrase
        // Split by characters or by escaped sequences (like \( and \))
        .split(/(\\\\[^\\]|.)/)
        .filter(Boolean)
        .reduce((acc, char, index, arr) => {
            // If the character is not an escaped sequence, add [\\s]* after it
            if (!char.startsWith('\\')) {
                acc.push(char, '[\\s]*');
            } else {
                acc.push(char);
            }

            // If the next character is an escaped sequence, add [\\s]* before it
            if (arr[index + 1]?.startsWith('\\')) {
                acc.push('[\\s]*');
            }

            return acc;
        }, [])
        .join('');

/**
 * Returns the source pattern of a regex
 *
 * @param {RegExp|string} regex
 * @returns {string} pattern
 */
export const getPattern = (regex) =>
    regex instanceof RegExp ? regex.source : new RegExp(regex).source;

/**
 * Creates a negative lookahead pattern for a given regex to exclude matches
 *
 * @param {RegExp|string} regex
 * @returns {string} negative lookahead pattern
 */
export const negativeLookahead = (regex) => `(?!${getPattern(regex)})`;

/**
 * Creates a negative lookbehind pattern for a given regex to exclude matches
 *
 * @param {RegExp|string} regex
 * @returns {string} negative lookbehind pattern
 */
export const negativeLookbehind = (regex) => `(?<!${getPattern(regex)})`;

/**
 * Creates a negative lookbehind pattern to exclude matches
 * if the match is preceded by a currency sign
 *
 * @param {number} maxDigits - maximum number of digits to allow after the currency sign
 * @param {string[]} signs - currency signs to allow
 * @returns {string} negative lookbehind pattern
 */
export const allowMoney = (maxDigits = 5, signs = currencySigns) => {
    // temp false return to avoid breaking the code
    // TODO - Fix this for backwards compatibility on ios
    return '';

    // return signs
    //     .map((sign) => {
    //         return Array.from({ length: maxDigits }, (_, i) => {
    //             return negativeLookbehind(`${sign}${`\\d`.repeat(i + 1)}`);
    //         }).join('');
    //     })
    //     .join('');
};
