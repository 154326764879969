import {
    timeSeparators,
    currencySigns,
    bannedPhrases,
    allowedPhrases,
    domainExt,
    countablePhrases,
} from './disintermediation';

import {
    getPattern,
    negativeLookahead,
    negativeLookbehind,
    allowMoney,
} from '@/utils/Regex/RegexHelper';

const moneyRegex = new RegExp(
    `\\b(?:${currencySigns.join('|')})\\s?\\d+(?:[,.]\\d{1,2})?\\b`,
    'g'
);

const dateRegex =
    /((0?[1-9]|[12][0-9]|3[01])(\/|-))?(0?[1-9]|1[1,2])(\/|-)(((19|20)\d{2})|2\d{1})/g;

const timeRegex = new RegExp(
    `([0-1]?[0-9]|2[0-4])(${timeSeparators.join('|')})([0-5][0-9])`,
    'g'
);

const timeNegativeLookahead = negativeLookahead(
    `(\\d{0,2}\\W)?` + // allow time range e.g 6:30/7:00 or 6-7:00
        getPattern(timeRegex)
);

export const ukPhoneNumberRegexEdge = new RegExp(
    '^(((\\+?44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+?44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+?44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$'
);

export const ukPhoneNumberRegex =
    /(\+?\d{2}\s?)?(\d{4}|\(\d{4}\))(\s?\d{3}\s?\d{3}|\s?\d{2}\s?\d{4}\s?\d{4})|\(\d{10,11}\)|\+(\w+)/g;

// Phone numbers
export const phoneRegex =
    /(\b\d{1,3}[-\s]?){9,}\d\b|(\+?\d{2,4}\s?)?(\(\d{2,4}\)|\d{2,4})(\s?\d{2,4}){2,3}\b|(\b\d(-\d){9,}\d\b)|(\b0\d{4}\s\d{3}\s\d{3}\b)/g;

export const longNumberRegex = new RegExp(
    `\\b\\d{5,}\\b` + allowMoney(5), // Allow up to 5 digits after currency signs
    'g'
);

// Number separated by any special character
export const digitsSeparatedRegex = new RegExp(
    negativeLookahead(dateRegex) +
        negativeLookahead(`(\\d|\\s|\\W){0,9}(hours?|o'?clock)`) + // exclude when followed by "hours" or "o'clock"
        timeNegativeLookahead +
        `\\b(?:\\d+(?=[^\\w\\s*]|\\s)[\\x20*]?[^\\w\\s*]?[\\x20*]?){3,}\\b`,
    'g'
);

// String of words containing numbers
export const numberWordRegex = new RegExp(
    `\\b(zero|one|two|three|four|five|six|seven|eight|nine|ten)(?:\\s+(?:zero|one|two|three|four|five|six|seven|eight|nine|ten))+\\b` +
        negativeLookahead(`\\s*(?:${countablePhrases.join('?|')})`),
    'gi'
);

// Instagram tags
export const instagramRegex = /@[\s]*[a-zA-Z]+/gi;

// Matches words followed by numbers, but not dates, currency, or countablePhrases
// (max 15 characters between word and number to avoid matching normal sentences but still match "one then 2" or "one followed by 2")
export const wordNumberRegex = new RegExp(
    `\\b(zero|one|two|three|four|five|six|seven|eight|nine)\\b` +
        `(?![\\d]{1,2}[\\/][\\d]{1,2}(?:[\\/][\\d]{2,4})?|\\s*(?:${countablePhrases.join(
            '?|'
        )}))` +
        `[^\\d\\n\\r\\t\\v\\f,]{0,15}\\d+` +
        negativeLookahead('\\s*(' + countablePhrases.join('?|') + ')') +
        allowMoney(),
    'gi'
);
export const mixedNumberWordRegex =
    /(?:zero|one|two|three|four|five|six|seven|eight|nine)\d+/gi;

const bannedPhrasesPattern = `\\b(?!${allowedPhrases.join(
    '|'
)})\\b(?:${bannedPhrases
    // .map(spaceBetweenChars) // Michelin star flagged as insta
    .join('|')})\\b`;
export const bannedPhrasesRegex = new RegExp(bannedPhrasesPattern, 'gi');

// Email regex
export const emailRegex =
    /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

// Website regex (matches any website except yhangry.com)
export const websiteRegex = new RegExp(
    `\\b(?!(https?:\\/\\/)?(www\\.)?yhangry\.com|.*maps\\..*|.*\\/maps\\/.*)((https?|ftp|smtp):\\/\\/)?(www\\.)?[a-z0-9]+\\.(?:${domainExt.join(
        '|'
    )}\\b)(\\/[a-zA-Z0-9#?&]+\\/?)*`,
    'gi'
);

export const consolidatedPhoneNumberRegex = new RegExp(
    negativeLookahead(dateRegex) +
        '(\\+44\\s?\\d{2,4}\\s?\\d{2,5}(\\s?\\d{2,5})?)' + // UK phone numbers with +44 (partial and complete)
        '|(\\(?0\\d{2,4}\\)?\\s?\\d{2,5}(\\s?\\d{2,5})?)' + // UK phone numbers without +44 (partial and complete)
        '|(\\b\\d{1,3}[-\\s]?){9,}\\d\\b' + // General phone numbers
        '|(\\b\\d(-\\d){9,}\\d\\b)' + // Numbers with dashes
        '|\\(\\d{10,11}\\)' + // UK phone numbers in parentheses
        '|\\b0\\d{4}\\s\\d{3}\\s\\d{3}\\b', // Additional pattern for UK phone numbers
    'gi'
);

export const regexes = {
    websiteRegex,
    wordNumberRegex,
    mixedNumberWordRegex,
    consolidatedPhoneNumberRegex,
    emailRegex,
    numberWordRegex,
    longNumberRegex,
    digitsSeparatedRegex,
    bannedPhrasesRegex,
    instagramRegex,
};
