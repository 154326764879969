import { createAction, createReducer, current } from '@reduxjs/toolkit';
import { each } from 'lodash';

import {
    FETCH_CHEF_ONBOARDING,
    FETCH_CHEF_ONBOARDING_ERROR,
    FETCH_CHEF_ONBOARDING_SUCCESS,
    ONBOARDING_UPDATE_VALUE,
    FETCH_CHEF_ONBOARDING_CONFIG,
    FETCH_CHEF_ONBOARDING_CONFIG_SUCCESS,
    FETCH_CHEF_ONBOARDING_CONFIG_ERROR,
    ONBOARDING_UPDATE_GROUPED_VALUE,
    ONBOARDING_ADD_IMAGE,
    ONBOARDING_REMOVE_IMAGE,
    SUBMIT_CHEF_ONBOARDING_PAGE,
    SUBMIT_CHEF_ONBOARDING_PAGE_SUCCESS,
    SUBMIT_CHEF_ONBOARDING_PAGE_ERROR,
} from '../constants/Actions/ChefOnboarding';

const initialState = {
    chef: {},
    personal: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        phoneIso: '44GB',
        website: '',
        instagram: '',
        contact_name: '',
        contact_phone_number: '',
        right_to_work_in_uk: null,
        health_and_safety_training: null,
        gross_income: '',
        how_much_would_you_like_to_earn: '',
        experience_classifications: null,
        target_events_count: null,
    },
    travel: {
        line_1: '',
        city: '',
        postcode: '',
        country: '',
        latitude: '',
        longitude: '',
        travel_distance: 20,
    },
    // quote: {
    //     line_1: '',
    //     city: '',
    //     postcode: '',
    //     country: '',
    //     latitude: '',
    //     longitude: '',
    //     travel_distance: 20,
    //     min_spend: 200,
    //     min_pp: 35,
    //     daily_chef_pay_rate: 150,
    //     no_daily_hire: false,
    //     hourly_chef_pay_rate: 20,
    //     no_hourly_hire: false,
    //     offers_delivery: null,
    //     delivery_min_spend: 100,
    // },
    // location: {
    //     line_1: '',
    //     city: '',
    //     postcode: '',
    //     country: '',
    //     latitude: '',
    //     longitude: '',
    //     travel_distance: 20,
    //     min_spend: 200,
    //     min_pp: 35,
    //     daily_chef_pay_rate: 150,
    //     no_daily_hire: false,
    //     hourly_chef_pay_rate: 20,
    //     no_hourly_hire: false,
    //     offers_delivery: null,
    //     delivery_min_spend: 100,
    // },
    experience: {
        chefs_experience: [],
        persona: '',
        faqs: [],
    },
    pricing: {
        min_spend: false,
        min_pp: false,
        daily_chef_pay_rate: 150,
        no_daily_hire: false,
        hourly_chef_pay_rate: 20,
        no_hourly_hire: false,
    },
    cuisines: {
        cuisines: [],
        extra_cuisines: [],
        dietaries_covered: [],
    },
    dietaries: {
        dietaries_covered: [],
    },
    services: {
        event_type: [],
        day_events: [],
        other_services: '',
        access_to_helper: null,
        seated_guests: 15,
        standing_guests: 30,
        additional_helper_costs: 100,
    },
    delivery: {
        offers_delivery: null,
        delivery_min_spend: 100,
        delivery_distance: 10,
        delivery_fee: 0,
        packaged_meals: [],
        delivery_services: [],
        delivery_packaging: '',
        delivery_courier: '',
        kitchen_license: '',
    },
    extras: {
        service_type: ['1', '2', '3'],
        access_to_helper: null,
    },
    images: {
        chef_image: {
            files: [],
            uploadErrors: [],
            imageLoading: false,
            remove: [],
            default: '',
        },
        chef_cover_image: {
            files: [],
            uploadErrors: [],
            imageLoading: false,
            remove: [],
        },
        gallery: {
            files: [],
            uploadErrors: [],
            imageLoading: false,
            remove: [],
        },
    },
    bio: {
        bio: '',
        faqs: [],
    },
    preferences: {
        instant_book: true,
        request_to_book: false,
        quote_requests: true,
    },
    payments: {
        payment_account_id: '',
        url: '',
    },
    config: {
        errors: [],
        loading: false,
        hasLoaded: false,
        success: false,
        options: {},
    },
    status: {
        total: false,
        pages: {
            personal: false,
            travel: false,
            experience: false,
            pricing: false,
            cuisines: false,
            dietaries: false,
            services: false,
            delivery: false,
            extras: false,
            images: false,
            menu: false,
        },
    },
    errors: [],
    loading: false,
    hasLoaded: false,
    success: false,
};

export const onboarding = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_CHEF_ONBOARDING', (state, action) => {
            state.loading = true;
            state.errors = initialState.errors;
            state.success = initialState.success;
        })
        .addCase('FETCH_CHEF_ONBOARDING_ERROR', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
        })
        .addCase('FETCH_CHEF_ONBOARDING_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.status = action.payload.status;
            state.chef = action.payload.chef;

            each(action.payload.steps, (data, key) => {
                each(data, (attributeVal, attributeKey) => {
                    if (
                        attributeKey == 'chef_image' ||
                        attributeKey == 'chef_cover_image'
                    ) {
                        state[key][attributeKey].default =
                            attributeVal === null
                                ? initialState[key][attributeKey].default
                                : attributeVal;
                        state[key][attributeKey].files =
                            attributeVal === null
                                ? initialState[key][attributeKey].files
                                : attributeVal;
                    } else if (attributeKey == 'gallery') {
                        state[key][attributeKey].files =
                            attributeVal === null
                                ? initialState[key][attributeKey].files
                                : attributeVal;
                    } else {
                        state[key][attributeKey] =
                            attributeVal === null
                                ? initialState[key][attributeKey]
                                : attributeVal;
                    }
                });
            });
        })
        .addCase('ONBOARDING_UPDATE_VALUE', (state, action) => {
            each(action.payload.data, (value, key) => {
                let currentValue = action.payload.group
                    ? state[action.payload.page][action.payload.group][key]
                    : state[action.payload.page][key];

                if (action.payload?.method == 'add') {
                    currentValue = Array.isArray(value)
                        ? [...currentValue, ...value]
                        : [...currentValue, value];
                } else if (action.payload?.method == 'remove') {
                    currentValue = currentValue.filter(
                        (item) => item !== value
                    );
                } else {
                    currentValue = value;
                }

                if (action.payload.group) {
                    state[action.payload.page][action.payload.group][key] =
                        currentValue;
                } else {
                    state[action.payload.page][key] = currentValue;
                }
            });
        })
        .addCase('ONBOARDING_UPDATE_GROUPED_VALUE', (state, action) => {
            each(action.payload.data, (value, key) => {
                if (
                    state[action.payload.page][key].find(
                        (element) => element.value == value.value
                    )
                ) {
                    if (value.edit || value.variation) {
                        let objIndex = state[action.payload.page][
                            key
                        ].findIndex((element) => element.value == value.value);
                        state[action.payload.page][key][objIndex].variation =
                            value.variation;
                    } else {
                        state[action.payload.page][key] = state[
                            action.payload.page
                        ][key].filter((item) => item.value !== value.value);
                    }
                } else {
                    state[action.payload.page][key].push(value);
                }
            });
        })
        .addCase('FETCH_CHEF_ONBOARDING_CONFIG', (state, action) => {
            state.config.loading = true;
            state.config.errors = initialState.config.errors;
            state.config.success = initialState.config.success;
        })
        .addCase('FETCH_CHEF_ONBOARDING_CONFIG_ERROR', (state, action) => {
            state.config.loading = false;
            state.config.hasLoaded = true;
        })
        .addCase('FETCH_CHEF_ONBOARDING_CONFIG_SUCCESS', (state, action) => {
            state.config.loading = false;
            state.config.hasLoaded = true;
            state.config.options = action.payload;
        })
        .addCase('SUBMIT_CHEF_ONBOARDING_PAGE', (state, action) => {
            state.loading = true;
            state.errors = initialState.errors;
            state.success = initialState.success;
        })
        .addCase('SUBMIT_CHEF_ONBOARDING_PAGE_ERROR', (state, action) => {
            state.loading = false;
            state.errors = action.payload.errors;
            state.success = false;
        })
        .addCase('SUBMIT_CHEF_ONBOARDING_PAGE_SUCCESS', (state, action) => {
            state.loading = false;
            state.errors = initialState.errors;
            state.status = action.payload.status;
            state.success = true;
        });
});
