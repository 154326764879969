export const timeSeparators = [':', '.'];

export const currencySigns = ['£'];

export const allowedPhrases = [
    'google map',
    'google\\S', // google links - leave websiteRegex to handle it (for maps exception)
];

export const bannedPhrases = [
    'commission',
    'agency',
    'platform fee',
    'instagram',
    'my website',
    'gmail',
    // 'email',
    // 'whatsapp',
    'hotmail',
    'yahoo',
    'icloud',
    'outlook',
    'google',
    '20%',
    '20percent',
    'twenty percent',
    'twenty %',
    '20pc',
    'off platform',
    'social media',
    'insta',
    'my ig',
    'platform takes',
    'agency fee',
    'dotcom',
    // '.com', // blocks yhangry.com as well - case already handled in websiteRegex
    '\\(dot\\)',
    '\\(at\\)',
];

// Allow numbers when followed by these phrases
// use plural form of the word - it will match both singular and plural
export const countablePhrases = [
    'courses',
    'options',
    'people',
    'guests',
    'kids',
    'childrens',
    'adults',
    'dishes',
    'desserts',
    'mains',
    'sides',
    'starters',
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'pounds',
    'veg',

    'gf',
    'y\/?o', // y/o or yo
    'and',
    'or',
];

export const domainExt = [
    'com',
    'co',
    'net',
    'org',
    'edu',
    'gov',
    'biz',
    'info',
    'jobs',
    'me',
    'io',
    'ai',
    'blog',
    'app',
    'tech',
    'dev',
    'xyz',
    'eu',
    'uk',
    'london',
    'ca',
    'au',
    'us',
    'fr',
    'de',
    'es',
];
