const curYear = new Date().getFullYear();

let NYE = new Date(`${curYear}-12-31`);
NYE?.setHours(12, 0, 0, 0);
NYE = NYE?.toISOString() || '';

let christmas = new Date(`${curYear}-12-25`);
christmas?.setHours(12, 0, 0, 0);
christmas = christmas?.toISOString() || '';

export { NYE, christmas, curYear };
