import { createRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateMeta } from '@/actions/wizard/RequestWizardActions';
import Feature from '@/utils/Features/Feature';

export const useStep = () => {
    const dispatch = useDispatch();
    const { meta, options, request } = useSelector(
        (state) => state.requestWizard
    );

    useEffect(() => {
        $('#' + meta.modalId).scrollTop(0);
    }, [meta.step]);

    /**
     * MARK: Helpers
     */

    const steps = options?.steps?.map((step, idx) => ({
        ...step,
        index: idx, // Used to track step number & navigation
        nodeRef: createRef(), // Used for transitioning between steps
    }));

    const activeStep = steps?.find((step) => step.name === meta?.stepName);

    let availableSteps = steps?.filter((step) => {
        // let personalisationCondition = true;
        let blockedSteps = [];

        let isRentedVenueFlow =
            meta?.features?.feature_wizard_rented_venue_flow;

        let isStrFlow = meta?.features?.feature_wizard_str;

        let isStrTest = isRentedVenueFlow && isStrFlow;

        // Remove food type step for multi-day chef hire and full-time chef requests
        if (
            request.type == 'Multi-day chef hire' ||
            request.type == 'Full-time chef' ||
            (isStrTest && request.type != 'Restaurant like meal')
        ) {
            blockedSteps.push('food-type');
        }

        if (isStrTest) {
            blockedSteps.push('event-type');
        }

        return (
            step.include &&
            !blockedSteps.includes(step.name) &&
            // For when A/B tests are running
            (step.variants?.length === 0 ||
                step.variants.every((v) => meta.features?.[v] === true))
        );
    });

    // reset the index on availableSteps now we have conditionally remove some steps
    availableSteps = availableSteps.map((step, idx) => ({
        ...step,
        index: idx + 1,
    }));

    const isUnlocked = (stepIndex) => {
        if (!stepIndex) return false;

        const step = availableSteps.find((s) => s.index === stepIndex);
        if (step.index < activeStep.index) return true;
        if (step.isComplete) return true;
        if (availableSteps.find((s) => s.index === step.index - 1)?.isComplete)
            return true;
        return false;
    };

    /**
     * MARK: Navigation
     */

    const next = (stepsToSkip = 0, skipToIncompleteStep = true) => {
        let nextSteps = availableSteps?.filter(
            (step) =>
                step.index > meta.step &&
                (skipToIncompleteStep ? !step.isComplete : true)
        );

        if (!nextSteps.length) {
            // Fallback to any incomplete step
            nextSteps = availableSteps?.filter((step) => !step.isComplete);
        }

        // Last step
        if (!nextSteps.length) return;

        const nextStep = nextSteps[stepsToSkip] ?? nextSteps[0]; // fallback to first upcoming step

        dispatch(
            updateMeta({
                step: nextStep.index,
                stepName: nextStep.name,
                stepPrev: meta.stepName,
                stepAction: 'next',
            })
        );
    };

    const prev = (stepsToSkip = 0) => {
        const prevSteps = availableSteps
            ?.sort((a, b) => b.index - a.index)
            ?.filter((step) => step.index < meta.step);

        // First step
        if (!prevSteps.length) return;

        const prevStep = prevSteps[stepsToSkip] ?? prevSteps[0]; // fallback to first previous step

        dispatch(
            updateMeta({
                step: prevStep.index,
                stepName: prevStep.name,
                stepPrev: meta.stepName,
                stepAction: 'back',
            })
        );
    };

    const toStep = (targetStepKey) => {
        if (!targetStepKey) return;

        const targetStep = availableSteps.find(
            (step) =>
                step.index === targetStepKey || step.name === targetStepKey
        );

        if (!targetStep) return;

        dispatch(
            updateMeta({
                step: targetStep.index,
                stepName: targetStep.name,
                stepPrev: meta.stepName,
                stepAction:
                    targetStep.index > meta.step
                        ? 'jumpForward'
                        : 'jumpBackward',
            })
        );
    };

    return {
        steps,
        activeStep,
        availableSteps,
        isUnlocked,
        next,
        prev,
        toStep,
    };
};
