import Axios from 'axios';
import { v4 as uuid } from 'uuid';
class Segment {
    isInitialised = () => {
        if (window.analytics) return true;

        return false;
    };

    appendFeatureFlags = (data) => {
        // Add feature flags to data attributes
        return {
            ...(window.globalFeatures ?? {}),
            ...data,
        };
    };

    track = async (event_name, data = {}) => {
        const isInitialised = this.isInitialised() ? true : false;

        if (isInitialised) {
            analytics.track(event_name, this.appendFeatureFlags(data));
        }

        await Axios.post('/track/event', {
            event_name: event_name,
            properties: data,
            url: window.location.href,
            routeName: window.isInertia ? route().current() : null,
            isClientSide: isInitialised,
        })
            .then(function (response) {
                // console.log('tracking: ', response.data);
                if (response.data?.properties) {
                    data = response.data.properties;
                }
            })
            .catch(function (error) {
                console.log('tracking error: ', error);
            });
    };

    identify = (data = {}, user = null) => {
        user = user ?? window.userId ?? null;
        // window.visitorId ?? // Use Visitor ID if user is not provided
        // uuid();

        if (this.isInitialised() && user) {
            analytics.identify(user, this.appendFeatureFlags(data));
        }
    };

    pageView = (category, name, data = {}) => {
        if (this.isInitialised()) {
            analytics.page(category, name, this.appendFeatureFlags(data));
        }
    };
}

export default new Segment();
