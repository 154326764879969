import { createAction, createReducer } from "@reduxjs/toolkit";

import {
    CHEF_REVIEW_SUBMIT,
    CHEF_REVIEW_SET_DEFAULT,
    CHEF_REVIEW_SUBMIT_ERROR,
    CHEF_REVIEW_SUBMIT_SUCCESS,
    CHEF_REVIEW_UPDATE_ATTRIBUTE,
    CHEF_REVIEW_PAYMENT_COMPLETE,
} from "../constants/ActionTypes";
import { chefs } from "../utils/chefs";

const initialState = {
    review: {
        feedback: "",
        card_id: null,
        experince: "",
        tip_amount: 0,
        chef_food_rating: 0,
        yhangry_feedback: "",
        cleanliness_rating: 0,
        chef_storytelling_rating: 0,
        overall_experince_rating: 0,
        images: {
            files: [],
            uploadErrors: [],
            imageLoading: false
        }
    },
    errors: [],
    chef_slug: "",
    chef_name: "",
    secret: false,
    loading: false,
    success: false,
    payment_id: false,
    selected_card: false,
    chef_refferal_code: "",
    customer_referral_code: "",
    order_booking_reference: "",
    loading_copy: "Submitting review...",
};

export const chefReview = createReducer(initialState, (builder) => {
    builder
        .addCase("CHEF_REVIEW_SUBMIT", (state, action) => {
            state.loading = true;
            state.secret = initialState.secret;
            state.errors = initialState.errors;
            state.success = initialState.success;
            state.payment_id = initialState.payment_id;
            state.loading_copy = initialState.loading_copy;
        })
        .addCase("CHEF_REVIEW_SET_DEFAULT", (state, action) => {
            state[action.payload.attribute] = action.payload.value;
        })
        .addCase("CHEF_REVIEW_SUBMIT_ERROR", (state, action) => {
            state.loading = false;
            state.secret = initialState.secret;
            state.success = initialState.success;
            state.payment_id = initialState.payment_id;
            state.loading_copy = initialState.loading_copy;
            state.errors = action.payload?.errors ? Object.entries(action.payload.errors) : action.payload && action.payload !== "" ? action.payload : ["There was an error while submitting your review, please try again."];
        })
        .addCase("CHEF_REVIEW_SUBMIT_SUCCESS", (state, action) => {
            state.success = true;
            state.errors = initialState.errors;
            state.secret = action.payload.secret;
            state.loading_copy = "Making payment...";
            state.loading = action.payload.secret ? true : false;
            state.payment_id = action.payload.feedback.payment_id;
        })
        .addCase("CHEF_REVIEW_UPDATE_ATTRIBUTE", (state, action) => {
            state.review[action.payload.attribute] = action.payload.value;
        })
        .addCase("CHEF_REVIEW_PAYMENT_COMPLETE", (state, action) => {
            state.success = true;
            state.loading = false;
            state.secret = initialState.secret;
            state.errors = initialState.errors;
            state.payment_id = initialState.payment_id;
            state.loading_copy = initialState.loading_copy;
            state.review.tip_amount = initialState.review.tip_amount;
        });
});
