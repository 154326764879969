import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import { useStep } from '@/hooks/wizard/useStep';
import useLocation from '@/hooks/wizard/useLocation';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import RequestWizardLoading from '@/views/Wizards/RequestWizard/States/RequestWizardLoading';
import Input from '@/elements/Input';
import { debounce } from 'lodash';

export default function LocationStep() {
    const dispatch = useDispatch();
    const { activeStep } = useStep();
    const {
        autoComplete,
        filteredCities,
        loadMore,
        resetPage,
        toComparable,
        getMatchedLocation,
    } = useLocation();
    const { request, options, status } = useSelector(
        (state) => state.requestWizard
    );
    const [search, setSearch] = useState(request.postcode);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [recentAction, setRecentAction] = useState(null);

    const delayMenu = useCallback(
        debounce((v) => setMenuIsOpen(v), 300),
        []
    );

    const mappedPostcodes = options.postcodes?.map((postcode) => {
        return { label: postcode, value: toComparable(postcode) };
    });

    const suggestions = [...mappedPostcodes, ...filteredCities];

    useEffect(() => {
        if (activeStep.isComplete && suggestions.length < 2) {
            delayMenu.cancel();
            setMenuIsOpen(false);
            return;
        }

        if (
            recentAction !== 'set-value' &&
            recentAction !== 'menu-close' &&
            recentAction !== 'input-blur'
        ) {
            delayMenu(true);
        }
    }, [activeStep.isComplete, suggestions]);

    useEffect(() => {
        const matchedLocation = getMatchedLocation();
        setSearch(matchedLocation ?? request.postcode);

        // Blur input when location is valid and only one suggestion left
        if (matchedLocation && suggestions.length === 1) {
            $('.multi-select__input').blur();
        }
    }, [request.postcode]);

    const handleChange = (postcode) => {
        resetPage();
        dispatch(updateRequest('postcode', postcode));

        if (postcode.length < 2) return;
        autoComplete(postcode);
    };

    if (status.loading) {
        return <RequestWizardLoading />;
    }

    return (
        <Step size="md">
            <h4 className="step-title">Where?</h4>
            <p className="step-info text-wrap">Enter your postcode or city. Available UK-wide.</p>
            <Input
                placeholder="Search postcode or city..."
                value={request.postcode}
                handleChange={(v) => handleChange(v.label)}
                inputType={`select-creatable`}
                options={suggestions}
                className={`${suggestions.length === 0 ? '-empty' : ''} ${
                    menuIsOpen ? '' : '-closed'
                }`}
                creatableProps={{
                    value: '',
                    inputValue: search,
                    onInputChange: (v, actionMeta) => {
                        setRecentAction(actionMeta.action);
                        if (
                            actionMeta.action === 'set-value' ||
                            actionMeta.action === 'menu-close' ||
                            actionMeta.action === 'input-blur'
                        ) {
                            setMenuIsOpen(false);
                            return; // Prevent clearing input on blur
                        }
                        handleChange(v);
                    },
                    // autoFocus: true, // causing transition flicker
                    menuIsOpen: true,
                    noOptionsMessage: () => 'Loading...',
                    isValidNewOption: () => false, // disable creating new options
                    blurInputOnSelect: true,
                    captureMenuScroll: true,
                    onMenuScrollToBottom: () => loadMore(),
                }}
            />
        </Step>
    );
}
